import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import PreLoader from './components/PreLoader';

const App = () => {
  return (
    <>
    <PreLoader />
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      
      <Header />
      <Banner />
      <Nav />
      <About />
      <Services />
      <Work />
      <Contact />
      
    </div>
    </>
  );
};

export default App;
